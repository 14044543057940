import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/Cyber/CyberSafety/1.jpg';
// import p2 from 'assests/Photos/Clubs/Cyber/CyberSafety/2.jpg';
// import p3 from 'assests/Photos/Clubs/Cyber/CyberSafety/3.jpg';
// import p4 from 'assests/Photos/Clubs/Cyber/CyberSafety/4.jpg';
// import p5 from 'assests/Photos/Clubs/Cyber/CyberSafety/5.jpg';
import CyberClub from 'views/CyberClub';


import { Typography } from '@mui/material';
import Container from 'components/Container';

const CyberSafety = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/CyberSafety/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/CyberSafety/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/CyberSafety/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/CyberSafety/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/Cyber/CyberSafety/5.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
  


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Movie Creation on Cyber Safety
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
     Class: 6 to 8  ,  Date: 24 January 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Cyber Association of National Public School, Yeshwanthpur conducted the finals of the Inter-house competition in Creating a Short Movie on, “Cyber Safety” on 24 January 2023 for the students of Classes 6 to 8.
        <br/><br/>
        The finalists from the four houses prepared the movie using the Windows Movie Maker as their platform. Students participated enthusiastically and exhibited their creative and innovative skills to culminate the overall presentation. They were judged on the following rubrics: Creativity, Innovation, Animation and Visual Effects.

        <br></br>
        
        The contribution of the team members played a crucial role in deciding the winners. Aether house secured the first place. Students realised that “Cyber Safety “is a shared responsibility and promised to be cautious while <strong>surfing the internet.</strong> 

        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
      "Movie Making Is the Ultimate Team Sport"
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <CyberClub />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default CyberSafety;